$ember-power-select-trigger-border: initial;
$ember-power-select-active-trigger-border: initial;
$ember-power-select-trigger-default-border-radius: 0;

.ember-power-select-typeahead-trigger {
  border: none;
  border-radius: 0;
}

.ember-power-select-typeahead-input {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
  bottom: 0;
  border: $ember-power-select-default-border;
  outline: none;

  & :focus {
    border: $ember-power-select-default-focus-border;
  }
}
