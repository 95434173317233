@import "bootstrap";
@import "ember-power-select";
@import 'ember-power-select-typeahead';
@import "ember-dialog";
@import 'signup';
@import 'profile';
@import 'landing/animate';
@import 'landing/main';

body {
  width: 100%;
}
/*body > .ember-view {
    height: 100%;
}*/
html, body, #map-container {
    height: 100%;
}

.highlighted {
  color: #999;
}

.hide-overflow {
  overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    /*width:100%;*/
    display:block;
}
.text-nowrap {
/*
    white-space: nowrap;
    text-overflow: ellipsis;
    */
}

#dz-map-container {
  /*width: 300px;*/
  height: 250px;
  padding: 5px;
}

.dev-top-stripe {
  height: 5%;
  padding: 5px;
  background-color: #c0a16b;
}
.fill {
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  width: auto;
  height: auto;
}
.fill-topless {
  top: 0;
}
a {
  cursor: pointer;
}
.form-control {
  height: auto;
}
.navbar {
    margin-bottom: 0px;
}
.navbar-nav > li > div {
    padding-top: 15px;
    padding-bottom: 15px;
}
.navbar-nav > li > a {
  padding-top: 5px;
  padding-bottom: 5px;
}
.navbar-stacked a {
  color: #999;
}
.navbar-stacked .alert {
  margin-bottom: 0px;
}
.navbar-stacked .highlighted a, .navbar-stacked .highlighted .menu {
  color: #337ab7;
}

.navbar-stacked .menu {
  color: #777;
}
.navbar-stacked .menu-label {
  font-size: 12px;
  color: #999;
}

.navbar-nav a .menu-label {
  font-size: 11px;
  color: #ddd;
}

.container-bigpicture {
  padding-top: 36px;
}

.crowd-span {
  display: block;
  min-width: 100px;
  padding-left: 15px;
  padding-right: 5px;
}
.x-toggle-component {
  justify-content: flex-start !important;
  padding-left: 5px;
}
.x-toggle-light.x-toggle-btn {
  background: #bbb;
}
.x-toggle:checked + .x-toggle-light.x-toggle-btn {
    background: #5cb85c;
}

.windaloft-table {
    margin-bottom: 0px;
    background: #fff;
}

.spotassist-box {
  max-width: 540px;
}
.spotassist-box h5 {
  margin:0px;
}

.spotassist-box .navbar {
  min-height: 0px;
}

.navbar {
  min-height: 20px;
  background: rgba(3, 72, 99, 0.9);
}
.navbar .dropdown-menu {
  background: rgba(3, 72, 99, 0.9);
}
.bigpicture-dropdown-menu {
  min-width: 300px;
  padding: 10px;
}
.navbar .dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
  background: #337ab7;
}
.navbar-brand {
  /*height: auto;*/
}

.navbar a {
  color: #fff !important;
}
.navbar label {
  color: #fff !important;
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
  background-color: #337ab7;
}
.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  background-color: #337ab7;
}


.navbar-bigpicture .navbar-brand {
  padding-top: 5px;
  padding-bottom: 5px;
}

.spotassist-box .navbar-brand {
    padding: 5px 5px;
    height: 35px;
}
.spotassist-box .navbar-nav > li > a {
    padding-top: 8px;
    padding-bottom: 3px;
    font-size:18px;
}
.spotassist-box  .list-group-item-heading {
  padding-bottom: 3px;
}
.spotassist-box  .list-group-item-heading .label {
  padding-bottom: 3px;
  margin-right: 3px;
}
.spotassist-box  .list-group-item-heading .limited {
  width:200px;
  max-width:200px;
  /*
  float: right;*/
  padding-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow:ellipsis;

}
.spotassist-box .list-group-item-text {
  font-size: 12px;
  color: #929496;
  white-space: nowrap;

}
 .g-map-canvas {
  width: 100%;
  height: 600px;
}

.leaflet-container {
  width: 100%;
  height: 100%;
  z-index: 10;
  //height: 600px;
}

.leaflet-popup-content {
    margin: 0px;
}
.leaflet-popup-content .panel {
  margin-bottom: 0px;
  border: 0px;
}
.leaflet-popup-content .panel-body {
  padding-top: 0px;
  padding-bottom: 0px;
}
.leaflet-popup-content-wrapper {
  border-radius: 4px;
}

.btn-store {
  padding: 0px;
}
.panel-heading {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}
/*
.panel-heading a {
  color: #2A5DB0;
}
*/
.panel-heading .btn-call-to-action {
  padding-left: 3px;
  padding-right: 3px;
}
.label-get-mobile-app {
  font-size: 85%;
}
/*
.panel-body {
    padding-top: 3px;
}
*/
.panel-body-edit {
  padding: 1px;
}
.panel-body-edit .input-group {
  padding-bottom:1px;
  width: 100%;
}
.crowd-container .panel {
  margin-bottom: 3px;
}
.panel-mobile-info {
  margin-bottom: 0px;
}
.panel-mobile-info .panel-heading {
  padding-left: 15px;
}

.crowd-container .panel-heading1:hover {
  background: #eee;
  cursor: pointer;
}
.input-group-addon {
  border-radius: 0px;
}
.input-group-addon-text {
  width: 130px;
  text-align: left;
}
.input-group-addon-widget {
  width: 150px;
  text-align: left;
}

.panel-rounded {
	border-radius: 12px;
	border: 0px;
}
.panel-heading-rounded {
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}
.dz_icon_glyph {
  color: #2A5DB0;
  font-size: 1.1em;
  padding: 5px;
  margin-right:10px;
}

.omnibox-container-mobile {
  display: none;
  width: 100%;
  z-index: 100; /* new in ember-leaflet 3 */
}
.omnibox-container {
    /*top: 10;*/
    position: absolute;
    z-index: 100; /* new in ember-leaflet 3 */
    width: 100%;
}
.omnibox-mobile {
  margin: 0px !important;
  max-width: 100%;
}
#omnibox {
    position: absolute;
    left: 0px;
    margin: 9px;
    top: 0px;
    z-index: 100; /* new in ember-leaflet 3 */
    transition: left 0.5s;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2),0 -1px 0px rgba(0,0,0,0.02);
}
#omnibox-right {
  position: absolute;
  right: 0;
  margin: 9px;
  top: 0px;
  z-index: 100; /* new in ember-leaflet 3 */
  transition: left 0.5s;
  //background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2),0 -1px 0px rgba(0,0,0,0.02);
}
#omnibox-right2 {
  position: absolute;
  right: 0;
  margin: 9px;
  top: 40px;
  z-index: 99; /* new in ember-leaflet 3 */
  transition: left 0.5s;
  //background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2),0 -1px 0px rgba(0,0,0,0.02);
}

.aupac-typeahead {
  /*width: 100%;*/
  min-width: 300px;
  /*font-size: 18px;
  padding: 5px;*/
}
.ember-power-select-typeahead-input {
  font-size: 16px;
  position: relative;
  height: 100%;
  padding: 6.5px;
}
.ember-power-select-trigger {
  /*position: absolute;*/
  padding: 0;
  height: 100%;
}
.ember-power-select-search-input {

}
.ember-power-select-options[role="listbox"] {
  max-height: 25em;
}


.loader {
  position: absolute;
  z-index: 1000;
  opacity: .8;
}
.loader-full {
  top: 100px;
  left:45%;
}
.loader-mobile {
  top: 50px;
  left:10px;
  right:10px;
}

.btn-noroundborder {
  border-radius: 0px;
}
.btn-social-dz {
    padding-left: 44px;
}
.btn-social-dz:hover, .btn:focus, .btn.focus {
    color: #fff;
    text-decoration: none;
}

.wind_image {
  width:20px;
  height:20px;
}
.direction-span {
  display: inline-block;
  width: 40px;
}

.row {
  /*margin-left: -15px;*/
  margin-right: 0px;
}
.omb_row-sm-offset-3 div:first-child[class*="col-"] {
  margin-left: 20px;
}

.omb_login .omb_authTitle {
    text-align: center;
}

.omb_login .omb_socialButtons a {
  color: white; // In yourUse @body-bg
  opacity:0.9;
}
.omb_login .omb_socialButtons a:hover {
  color: white;
  opacity:1;
}
.omb_login .omb_socialButtons .omb_btn-facebook {background: #3b5998;}
.omb_login .omb_socialButtons .omb_btn-twitter {background: #00aced;}
.omb_login .omb_socialButtons .omb_btn-google {background: #c32f10;}

.close-icon {
  background-image: url(/assets/images/close.png);
  background-size: 96px 24px;
  margin-top: 3px;
  height: 24px;
  width: 24px;
  cursor: pointer;
}
.iFrameContainer {
  /*padding: 20px;/
  background-color: #ffffff;
  border: 1px solid #ccc;*/
}
.iFrameCode {
  margin: 50%;
}


/* Sliding NAV */
.sidenav .navbar-header {
  float: none;
  border-bottom: 1px solid #ccc;
}
.sidenav .navbar-stacked {
  border-bottom: 1px solid #ccc;
}
.sidenav .navbar-header .navbar-right {
  margin-right: 0px;
}

/* The side navigation menu */
.sidenav {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 5001; /* Stay on top */
    top: 0;
    left: 0;
    background-color: #fff; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 0px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    border: solid 1px #ccc;
}
.ember-basic-dropdown-content {
  z-index: 10000;
}

/* The navigation menu links */
.sidenav a {
    padding: 8px 8px 8px 12px;
    text-decoration: none;
    font-size: 16px;

    display: block;
    transition: 0.3s
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover, .offcanvas a:focus{

}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
    transition: margin-left .5s;
    padding: 20px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
}

.crowd-container .alert {
  margin-bottom:0px;
  padding-top:5px;
  padding-bottom:5px;
}

.corridor-opacity {
  opacity: 0.6;
  z-index: 10!important;
}
.wind-line-opacity {
  opacity: 0.5;
  z-index: 15!important;
}
.wind-gust-opacity {
  opacity: 0.4;
  z-index: 14!important;
}

.dropdown-menu-right {
  right: 0px;
}

.dropdown-toggle-orange {
  background: #ec610d;
  background-color: #ec610d;
  background-image: linear-gradient(to bottom,#ce550d,#ec610d);
}

.slide_caption {
  /*top: 0;*/
  bottom: 0;
  display: block;
  position: absolute;
  width: 100%;
  visibility: visible;
  opacity: .8;
  color: #fff;
  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif;
  z-index: 100;
}
.slide_caption > div {
  background: #034863;
  background: rgba(3, 72, 99, 1);
  padding: 10px 20px;
}
.slide_caption p {
  margin: 0 0 0px;
}

.login-dropdown-menu {
  padding: 0px;
}
.login-container {
  min-width: 300px;
  /*padding: 14px 14px 0;*/
  margin-left: 0px;
  margin-right: 0px;
  overflow: hidden;
  background-color: rgba(255,255,255,.8);
}

.login-container h5 a, .login-container .form a, .login-container .form label, .login-container .bottom a {
  color: #337ab7 !important;
}
.login-container .title {
  text-align: center;
}

.social-buttons{
  margin:12px 0
}
.social-buttons a{
  width: 49%;
}
.btn-fb {
  color: #fff;
  background-color: #3b5998;
}
.btn-fb:hover {
  background-color: #53A3CD;
  color: #fff;
}
.btn-google {
  color: #fff;
  background-color: #dd4b39;
}

.label-email {
  font-size: 100%;
  margin-top: 5px;
}
.loader .share-panel {
  display: block;
}

.btn-want-it {
    color: #fff !important;
    background-color: #ec610d;
    border-color: #fff;
}

.out-polygon {
  stroke: #ccc;
  fill: #00aced;
}
.out-polygon-crowd {
  stroke: #ccc;
  fill: #ce550d;
}

.out-polygon-highlight {
  stroke: #ccc;
  fill: #ce550d;
}
