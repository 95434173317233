/*!
 * SNOW Landing Page Theme
 *
 * Created by Visual Soldiers
 * Visit us at http://www.visualsoldiers.com
 *
 * Handcrafted with love in Atlanta!
 */


/* ==========================================================================
   Global styles
   ========================================================================== */
h1, h2, h3, h4, h5, h6, p, .btn, .navbar-nav a, .form-control, .list-inline, small, #pricing {
  font-family: 'Montserrat', sans-serif;
}

.container-landing p, .lead {
	font-family: 'Raleway', sans-serif;
}

.container-landing h1 {
  font-size: 55px;
  line-height: 55px;
  letter-spacing: -1px;
  color: #ffffff;
  font-weight: 700;
}

.container-landing h2 {
  font-size: 40px;
  line-height: 40px;
  letter-spacing: -0.5px;
  color: #ffffff;
  font-weight: 700;
}

.container-landing h3 {
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0px;
  color: #231f20;
  font-weight: 700;
}

.container-landing .lead {
  font-size: 21px;
  line-height: 26px;
  color: #ffffff;
  font-weight: 400;
}

.container-landing p {
  font-size: 17px;
  line-height: 22px;
  color: #929496;
  font-weight: 400;
}

/*------------------Utilities---------------------*/
a {
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  text-decoration: none;
}

a:hover, a:focus {
  text-decoration: none;
  color: #231f20;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -10%;
}

::selection {
	background: #231f20; /* Safari */
	color: #FFFFFF;
}
::-moz-selection {
	background: #231f20; /* Firefox */
	color: #FFFFFF;
}


/*------------------Buttons---------------------*/

.container-landing .btn:focus {
  outline: 0px auto -webkit-focus-ring-color;
  outline: none;
}

.container-landing .btn {
  text-transform: uppercase;
  border-radius: 4px;
  border: none;
}

.container-landing .btn-lg {
  font-size: 13px;
  line-height: 1.33;
  padding: 22px 30px;
  font-weight: 400;
  letter-spacing: 1px;
}

.container-landing .btn-sm {
  font-size: 11px;
  line-height: 1.33;
  padding: 10px 18px;
  font-weight: 400;
}

.container-landing .btn-wide {
  width: 100%;
  font-size: 13px;
  line-height: 1.33;
  padding: 18px 28px;
  font-weight: 400;
  letter-spacing: 1px;
}

.container-landing .btn-primary {
  /*background-color:#70cbce;*/
  background-color:rgba(61, 192, 245, 0.9);
  color: #ffffff;
}

.container-landing .btn-primary:hover, .container-landing .btn-primary:focus, .container-landing .btn-primary:active, .container-landing .btn-primary.active {
  background-color: #8be2e5;
  color: #ffffff;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

.container-landing .btn-secondary {
  background-color: #ffffff;
  color: #231f20;
}

.container-landing .btn-secondary:hover, .container-landing .btn-secondary:focus, .container-landing .btn-secondary:active, .btn-secondary.active {
  background-color: #ededed;
  color: #231f20;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}




/*-----------------Margins------------------*/
.margin-0 {
    margin-bottom: 0 !important;
}
.margin-10 {
    margin-bottom: 10px !important;
}
.margin-15 {
    margin-bottom: 15px !important;
}
.margin-20 {
    margin-bottom: 20px !important;
}
.margin-30 {
    margin-bottom: 30px !important;
}
.margin-40 {
    margin-bottom: 40px !important;
}
.margin-50 {
    margin-bottom: 50px !important;
}
.margin-60 {
    margin-bottom: 60px !important;
}
.margin-70 {
    margin-bottom: 70px !important;
}
.margin-80 {
    margin-bottom: 80px !important;
}
.margin-90 {
    margin-bottom: 90px !important;
}
.margin-100 {
    margin-bottom: 100px !important;
}


/*-----------------Colors------------------*/

.container-landing .black {
  color: #231f20;
}

.container-landing .blue {
  /*color: #70cbce;*/
  color: rgba(61, 192, 245, 0.9);
}

.container-landing .white {
  color: #ffffff;
}

.black-bg {
  background: #231f20;
}

.blue-bg {
  /*background: #70cbce;*/
  background: rgba(61, 192, 245, 0.9);
}

.white-bg {
  background: #ffffff;
}

.purple-bg {
	background: #6d3f96;
}

.container-landing .navbar-nav a {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 1px;
}

/* ==========================================================================
   Nav Styles
   ========================================================================== */
/*
.navbar {
    border: 0px solid rgba(0, 0, 0, 0);
    background: #70cbce;
    margin: auto;
    min-height: 60px;
    padding-top: 8px;
    position: fixed;
    top: -60px;
    transition: top 0.4s ease 0s;
}

.navbar.open {
    top: 0;
}

.navbar-inverse .navbar-nav > li > a, .navbar-nav a {
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 1px;
}

.navbar-inverse .navbar-nav > li > a:hover {
    color: #ffffff;
    opacity: 0.6;
}

.nav > li > a {
    position: relative;
    display: block;
    padding: 10px 10px;
}

.navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
    border-color: #4ec3cd;
}

.navbar-inverse .navbar-toggle {
    border-color: transparent;
}
*/

.logo img {
	margin-top: 6px;
}



/* ==========================================================================
   Main styles
   ========================================================================== */
header {
  background: url(images/spotassist-bigpicture.png) no-repeat center center;
  background-color: rgba(61, 192, 245, 0.9);
  width: 100%;
  padding: 20px 10px 60px 10px;
  /*height: 80vh;*/
  height: 80vh;
  overflow: hidden;
  background-size: contain;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
}
.header-info {
  position: absolute;
  /*bottom: 0;*/
  width: 100%;
  left: 0;
  right: 0;
  margin-left: 0px;
  margin-bottom: 12%;
}

.signin {
  padding-top: 9px;
}

.hidden {
  display: none;
}

.mouse-icon {
    border: 2px solid #fff;
    border-radius: 16px;
    display: block;
    height: 50px;
    margin: -100px auto 50px;
    position: relative;
    width: 30px;
    z-index: 10;
}
.mouse-icon .scroll {
    animation-delay: 0s;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: scrolling;
    animation-play-state: running;
    animation-timing-function: linear;
}
.mouse-icon .scroll {
    background: #fff none repeat scroll 0 0;
    border-radius: 10px;
    height: 10px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 4px;
    width: 4px;
}
@keyframes scrolling {
0% {
    opacity: 0;
    top: 5px;
}
30% {
    opacity: 1;
    top: 10px;
}
100% {
    opacity: 0;
    top: 25px;
}
}

.pad-xl {
	padding: 200px 0px;
}

.pad-lg {
	padding: 160px 0px;
}

.pad-sm {
	padding: 80px 0px;
}

.pad-xs {
	padding: 30px 0px;
}



#check-your-dropzone {
  background: url(landing/big-picture-tile.png) #231f20 no-repeat center center fixed;
  min-height: 300px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

hr.line {
	width: 100%;
	border: 2px solid;
}

.purple {
	border-color: #6d3f96;
	color: #6d3f96;
}

.blue {
	/*border-color: #70cbce;
	color: #70cbce*/

  border-color: rgba(61, 192, 245, 0.9);
  color: rgba(61, 192, 245, 0.9);

}

.yellow {
	border-color: #fdc05d;
	color: #fdc05d;
}

.iphone {
    position: relative;
    height: 200px;
}

.iphone img {
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 440px;
}


#invite {
  background: #f2f2f2;
}

.fa-envelope-o, .fa-thumbs-o-up {
  color: #4ec3cd;
  font-size: 22px;
  display: block;
  padding-top: 15px;
  width: 60px;
  height: 60px;
  border: 2px solid #4ec3cd;
  -moz-border-radius: 30px;
  -o-border-radius: 30px;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  margin: auto;
}



.news-container {
  padding: 10px 0px 20px;
  border-bottom: 1px dotted #d9d9d9;
}

.news-img {
  padding-right: 20px;
}

footer {
  background: #ffffff;
  padding: 40px 0px;
}

.social li a:hover {
  opacity: 0.5;
}



/*---------------Pricing Tables-------------------*/


#pricing {
  /*background: #70cbce;*/
  background: rgba(61, 192, 245, 0.9);
}

.pricing-container {
  padding-left: 0px;
  padding-right: 0px;
}

.pricing-table {
  background: transparent;
  margin-bottom: 50px;
  margin-top: 0px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.pricing-table.active {
  box-shadow: 0px 0px 12px rgba(41,46,50,0.6);
  position: relative;
  margin: auto;
  z-index: 200;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.pricing-table:hover {
  margin-top: -10px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.pricing-table li {
  padding-left: 20px;
  padding-right: 20px;
}

.headline {
  background: #231f20;
  color: #FFFFFF;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.price {
    background: none repeat scroll 0 0 #231f20;
    color: #FFFFFF;
    font-size: 32px;
    font-weight: bold;
    padding-bottom: 20px;
    padding-top: 20px;
}


.pricing-table:hover li.price  {
  background: #303236;
  -webkit-transition: all 0.85s ease-in-out;
  -moz-transition: all 0.85s ease-in-out;
  -o-transition: all 0.85s ease-in-out;
  transition: all 0.85s ease-in-out;
}

.price small {
  font-weight: 300;
  color: #929496;
}

.info {
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 300;
  font-size: 13px;
  color: #929496;
  background: #ffffff;
}

.features {
  color: #231f20;
  font-weight: bold;
  padding-top: 12px;
  padding-bottom: 15px;
  border-bottom: 1px dotted #E8EAEA;
  background: #ffffff
}

.features.first {
  border-top: 1px dotted #E8EAEA;
}

.features.last {
  padding-top: 17px;
  padding-bottom: 20px;
}

.features.last a {
	/*color: #70cbce;*/
  color: rgba(61, 192, 245, 0.9);
	font-size: 14px;
	letter-spacing: 1px;
}

.pricing-container .btn {
    border-radius: 0;
}




/* ==========================================================================
   Form styles
   ========================================================================== */
.container-landing .form-control, .container-landing .ember-power-select-trigger {
    background-color: #FFFFFF;
    background-image: none;
    border: 0px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
    color: #929496;
    display: block;
    font-size: 15px;
    height: 60px;
    line-height: 1.42857;
    padding: 6px 12px;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}

.container-landing .ember-power-select-typeahead-input {
  border: 0px solid transparent;
  font-size: 24px;
}

.container-landing .form-control::-moz-placeholder {
    color: #d9d9d9;
    font-size: 15px;
    opacity: 1;
}

.container-landing .form-control::-webkit-placeholder, .form-control::-o-placeholder {
    color: #d9d9d9;
    font-size: 15px;
    opacity: 1;
}

.container-landing .form-control::-o-placeholder {
    color: #d9d9d9;
    font-size: 15px;
    opacity: 1;
}




/* ==========================================================================
   Mobile styles
   ========================================================================== */
/* Landscape phones and down */
@media (max-width: 480px) {

.btn-lg {
  font-size: 12.5px;
  line-height: 1.33;
  padding: 16px 13px;
  letter-spacing: 0px;
}


.btn-wide {
  width: 100%;
  font-size: 12.5px;
  line-height: 1.33;
  padding: 16px 13px;
  letter-spacing: 0px;
}

#be-the-first {
      background-attachment: scroll;
  }

}


/* Landscape phone to portrait tablet */
@media (min-width: 481px) and (max-width: 767px) {

#be-the-first {
      background-attachment: scroll;
  }

}


/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {

#be-the-first {
      background-attachment: scroll;
  }

}



/* Desktops and laptops ----------- */
@media (min-width: 992px) and (max-width: 1199px) {

}



/* Large screens ----------- */
@media (min-width: 1200px) and (max-width: 3000px) {

}

.dropzone-status {
  /*background-color: #fff;*/
}


.half-rule {
  margin-left: 25%;
  text-align: left;
  width: 50%;
}

.badge-like {
  background-color: #f89406;
}

.share-button, .vk-share-button> span, .twitter-share-button> span {
  height: 40px;
  font-size: 16px;
}

.twitter-share-button> i, .fb-share-button svg, .vk-share-button svg {
  width: 20px;
  height: 20px;
}

.ember-power-select-option {
  font-size: 20px;
}

.page {
  /*position: relative;*/
  /*height:100%;*/
}

.popup {
  position:absolute;
  z-index:2;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:rgba(0,0,0,0.7);
  opacity:0;
  visibility:hidden;
  transition:.3s ease;
}

.show-popup .popup {
  opacity:1;
  visibility: visible;
}

.popup > iframe {
  position:absolute;
  top:50px;
  left:50%;
}

.popup > iframe {
  margin-left:-400px;
}
.popup-mobile > iframe {
  margin-left:-150px;
}
